/* @import url("https://fonts.googleapis.com/css2?family=Poppins:200,300,400,500,600,700,800,900&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
/* 
::-webkit-scrollbar {
  width: .4rem;
  height: .4rem;
  background-color: transparent;
  border-radius: .5rem;
}

::-webkit-scrollbar-thumb {
  border-radius: .5rem;
  background-color: rgba(14, 0, 23, 0.4);
} */

::selection {
  background: #7c3aed;
  color: white;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Poppins", sans-serif; */
  font-family: 'Lato', sans-serif;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body {
  overflow: overlay;
}

.MainSection {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: url("https://uhdwallpapers.org/uploads/converted/20/01/14/the-mandalorian-5k-1920x1080_477555-mm-90.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

.MainContainer {
  position: relative;
  width: calc(100% - 100px);
  min-height: calc(100vh - 280px);
  min-height: 300px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.MainHeader {
  position: absolute;
  top: -40px;
  right: 20px;
  left: 20px;
  padding: 20px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}

.MainHeader .logo {
  color: #8a2435;
  font-weight: 700;
  text-decoration: none;
  font: size 2em;
}

.MainHeader ul {
  display: flex;
}

.MainHeader ul li {
  list-style: none;
  margin: 0 10px;
}

.GoBackButton {
  border-radius: 26px;
  border: none;
  background-color: rgba(255, 255, 255, 0.5);
  color: #ff0058;
  font-weight: 900;
  cursor: pointer;
  text-decoration: none;
  padding: 8px 15px;
}

.MainHeader ul li a {
  text-decoration: none;
  font-weight: 500;
  color: #8a2435;
  padding: 8px 15px;
  border-radius: 26px;
}

.MainHeader ul li a:hover,
.MainHeader ul li a.active {
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

/* .MainHeader ul li .login {
  background: #ff9a9e;
  text-decoration: none;
  color: #f6416c;
  border-radius: 50px;
} */

.MainHeader ul li .login:hover {
  background: #f6416c;
  text-decoration: none;
  color: #4afff0;
}

.content {
  transform: translateX(-100px);
  max-width: 700px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  padding: 40px;
  padding-left: 110px;
}

.content h2 {
  font-size: 3em;
  color: #8a2435;
}

.content p {
  font-size: 1.2em;
  color: #8a2435;
  text-transform: capitalize;
}

.content a {
  display: inline-block;
  padding: 15px 30px;
  background: #fff;
  color: #8a2435;
  margin-top: 20px;
  border: radius 30px;
  font-size: 1.2em;
  font-weight: 500;
  text-decoration: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.imgBx img {
  max-width: 400px;
  animation: animateimg 4s linear infinite;
  height: 300px;
  border-radius: 100%;
}

@keyframes animateimg {

  0%,
  100% {
    transform: translateY(10px);
  }

  50% {
    transform: translateY(60px);
  }
}

.loginBox {
  background-color: #FFFF;
}

.sci {
  position: absolute;
  right: -25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sci li {
  list-style: none;
}

.sci li a {
  text-decoration: none;
  width: 50px;
  height: 50px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 50px 0;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
}

.sci li a img {
  transform: scale(0.6);
  transition: 0.5s;
}

.sci li:hover a img {
  transform: scale(0.6) rotate(360deg);
}

.copyrightText {
  position: absolute;
  bottom: 30px;
  left: 50px;
  color: #8a2435;
  margin-top: 80px;
}

@media (max-width: 1200px) {
  .MainContainer {
    position: relative;
    width: calc(100% - 20px);
  }
}

@media (max-width: 992px) {
  section {
    padding: 50px 10px 10px;
  }

  .MainContainer {
    flex-direction: column;
    height: auto;
    padding: 20px;
  }

  .MainContainer .content {
    transform: translateX(0px);
    max-width: 100%;
    padding: 30px;
    margin-top: 40px;
  }

  .MainHeader {
    position: relative;
    flex-direction: column;
    width: 100%;
    top: -60px;
    padding: 20px;
    left: 0;
    right: 0;
  }

  .MainHeader ul {
    margin-top: 10px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .MainHeader ul li {
    margin: 2px;
  }

  .MainHeader ul li a {
    display: inline-block;
  }

  .imgBx {
    margin-bottom: 50px;
  }

  .content a {
    font-size: 1em;
    padding: 10px 20px;
  }

  @keyframes animate {

    0%,
    100% {
      transform: translateY(-20px);
    }

    50% {
      transform: translateY(20px);
    }
  }

  .sci {
    position: relative;
    flex-direction: row;
    left: 0;
  }

  .sci li a {
    margin: 0 10px;
  }

  .copyrightText {
    position: relative;
    bottom: initial;
    left: inherit;
    width: 100%;
    color: #8a2435;
    text-align: center;
    margin-top: 40px;
  }
}

@media (max-width: 600px) {
  .content h2 {
    font-size: 1em;
    color: #8a2435;
  }

  .content p {
    font-size: 1em;
    color: #8a2435;
  }

  .imgBx img {
    max-width: 300px;
  }
}

@media (max-width: 440px) {
  .imgBx img {
    max-width: 260px;
    height: 200px;
  }
}

@media (max-width: 320px) {
  .imgBx img {
    max-width: 180px;
    height: 120px;
  }
}

@media (max-width: 260px) {
  .imgBx img {
    max-width: 200px;
    height: 170px;
  }
}

@media (max-width: 190px) {
  .imgBx img {
    max-width: 170px;
    height: 150px;
  }
}

.loader-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #242f3f;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 10000000000000000000000000000000000000000000000000000000;
}

.loader-wrapper #sectionloader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
  background: #042104;
  animation: animateBg 2s linear infinite;
  z-index: 10000000000000000000000000000000000000000;
}

@keyframes animateBg {
  0% {
    filter: hue-rotate(0deg);
  }

  100% {
    filter: hue-rotate(360deg);
  }
}

.loader-wrapper #sectionloader .loader {
  position: relative;
  width: 120px;
  height: 120px;
}

.loader-wrapper #sectionloader .loader span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(calc(18deg * var(--i)));
}

.loader-wrapper #sectionloader .loader span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #00ff0a;
  box-shadow: 0 0 10px #00ff0a, 0 0 20px #00ff0a, 0 0 40px #00ff0a,
    0 0 60px #00ff0a, 0 0 80px #00ff0a, 0 0 100px #00ff0a;
  animation: animatescale 2s linear infinite;
  animation-delay: calc(0.1s * var(--i));
}

@keyframes animatescale {
  0% {
    transform: scale(1);
  }

  80%,
  100% {
    transform: scale(0);
  }
}


:root {
  /*===== Colores =====*/
  --first-color: #9D4BFF;
  --first-color-dark: #23004D;
  --first-color-light: #A49EAC;
  --first-color-lighten: #F2F2F2;

  /*===== Font and typography =====*/
  /* --body-font: 'Open Sans', sans-serif; */
  --body-font: 'Lato', sans-serif;
  --h1-font-size: 1.5rem;
  --normal-font-size: .938rem;
  --small-font-size: .813rem;
}

#FormBody {
  /* background: url("/src/Images/Login_Bg.jpg");
  background-size: cover;
  background-repeat: no-repeat; */
  margin: 0;
  padding: 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  color: var(--first-color-dark);
}

#FormBody h1 {
  margin: 0;
}

#FormBody a {
  text-decoration: none;
}

#FormBody img {
  max-width: 100%;
  height: auto;
  display: block;
}

/*===== LOGIN =====*/
#FormBody .login {
  display: grid;
  grid-template-columns: 100%;
  height: 100vh;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

#FormBody .login__content {
  display: grid;
}

#FormBody .login__img {
  justify-self: center;
}

#FormBody .login__img img {
  width: 310px;
  margin-top: 1.5rem;
}

#FormBody .login__forms {
  position: relative;
  height: 368px;
}

#FormBody .loginform_reg {
  position: relative;
  height: 520px;
  /* margin-bottom: 2rem; */
}

#FormBody .login__regbox{
  margin-bottom: 3rem;
}

#FormBody .login__registre,
#FormBody .login__create {
  position: absolute;
  bottom: 1rem;
  width: 100%;
  background-color: var(--first-color-lighten);
  padding: 2rem 1rem;
  border-radius: 1rem;
  text-align: center;
  box-shadow: 0 8px 20px rgba(35, 0, 77, .2);
  animation-duration: .4s;
  animation-name: animate-login;
}

@keyframes animate-login {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

#FormBody .login__title {
  font-size: var(--h1-font-size);
  margin-bottom: 2rem;
}

#FormBody .login__box {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: .5rem;
  /* padding: 1.125rem 1rem; */
  /* background-color: #FFF; */
  margin-top: 1rem;
  /* border-radius: .5rem; */
}

#FormBody .login__boxRadio {
  display: flex;
  grid-template-columns: max-content 1fr;
  column-gap: .5rem;
  flex-direction: row;
  padding: 1.125rem 1rem;
  /* background-color: #FFF; */
  margin-top: 1rem;
  border-radius: .5rem;
}

#FormBody .login__inputRadio {
  border: none;
  outline: none;
  font-size: var(--normal-font-size);
  font-weight: 700;
  color: var(--first-color-dark);
  width: 80px;
}

#FormBody .login__inputRadio ::placeholder {
  font-size: var(--normal-font-size);
  font-family: var(--body-font);
  color: var(--first-color-light);
}

#FormBody .login__box {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: .5rem;
  /* padding: 0.5rem 0.5rem; */
  /* background-color: #FFF; */
  margin-top: 1rem;
  /* border-radius: .5rem; */
}

textarea:focus,
input:focus {
  outline: none;
}

#FormBody .login__box_pfp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* grid-template-columns: max-content 1fr;
  column-gap: .5rem; */
  padding: 1.125rem 1rem;
  /* background-color: #FFF; */
  margin-top: 1rem;
  border-radius: .5rem;
}

#FormBody .login__icon {
  font-size: 1.5rem;
  color: var(--first-color);
}

#FormBody .login__input {
  font-size: var(--normal-font-size);
  font-weight: 700;
  color: var(--first-color-dark);
  width: 100%;
}

#FormBody .login__input::placeholder {
  font-size: var(--normal-font-size);
  font-family: var(--body-font);
  color: var(--first-color-light);
}

#FormBody .login__forgot {
  display: block;
  width: max-content;
  margin-left: auto;
  margin-top: .5rem;
  font-size: var(--small-font-size);
  font-weight: 600;
  color: rgb(0, 102, 255);
}

#FormBody .login__button {
  display: block;
  padding: 1rem;
  margin: 2rem 0;
  background-color: rgb(10,0,69);
  color: #FFF;
  font-weight: 600;
  text-align: center;
  border-radius: .5rem;
  transition: .3s;
}

#FormBody .login__button:hover {
  background-color: var(--first-color-dark);
}

#FormBody .login__account,
#FormBody .login__signin,
#FormBody .login__signup {
  font-weight: 600;
  font-size: var(--small-font-size);
}

#FormBody .login__account {
  color: rgb(59, 59, 59);
}

#FormBody .login__signin,
#FormBody .login__signup {
  color: rgb(0, 102, 255);
  cursor: pointer;
}

#FormBody .login__social {
  margin-top: 2rem;
}

#FormBody .login__social-icon {
  font-size: 1.5rem;
  color: var(--first-color-dark);
  margin: 0 1.5rem;
}

/*Show login*/
.block {
  display: block;
}

/*Hidden login*/
.none {
  display: none;
}

/* ===== MEDIA QUERIES =====*/
@media screen and (min-width: 576px) {
  #FormBody .login__forms {
    width: 348px;
    justify-self: center;
  }
}

@media screen and (min-width: 1024px) {
  #FormBody .login {
    height: 100vh;
    overflow: hidden;
  }

  #FormBody .login__content {
    grid-template-columns: repeat(2, max-content);
    justify-content: center;
    align-items: center;
    margin-left: 10rem;
  }

  #FormBody .login__img {
    display: flex;
    width: 600px;
    height: 588px;
    background-color: var(--first-color-lighten);
    border-radius: 1rem;
    /* padding-left: 1rem; */
  }

  #FormBody .login__img img {
    width: 500px;
    margin-top: 0;
  }

  #FormBody .login__registre,
  #FormBody .login__create {
    left: -11rem;
  }

  #FormBody .login__registre {
    bottom: -2rem;
  }

  #FormBody .login__create {
    bottom: -5.5rem;
  }
}

.top-to-btm {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: translateY(-60px);
}

.icon-position {
  /* position: fixed;
  right: 30vw; */
  bottom: 150px;
  z-index: 40;
}

.icon-style {
  background-color: #551b54;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  animation: movebtn 4s ease-in-out infinite;
  transition: all 0.5s ease-in-out;
}

.icon-style:hover {
  animation: none;
  background: #fff;
  color: #551b54;
  border: 2px solid #551b54;
}

@keyframes movebtn {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}








@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

* {
  outline: none;
  box-sizing: border-box;
}

:root {
  /* --theme-bg-color: rgba(16 18 27 / 40%); */
  /* --border-color: rgba(113 119 144 / 25%); */
  /* --theme-color: #f9fafb; */
  /* --inactive-color: rgb(113 119 144 / 78%); */
  --body-font: "Poppins", sans-serif;
  /* --hover-menu-bg: rgba(12 15 25 / 30%); */
  /* --content-title-color: #999ba5; */
  --content-bg: rgb(146 151 179 / 13%);
  /* --button-inactive: rgb(249 250 251 / 55%); */
  /* --dropdown-bg: #21242d; */
  /* --dropdown-hover: rgb(42 46 60); */
  /* --popup-bg: rgb(22 25 37); */
  /* --search-bg: #14162b; */
  /* --overlay-bg: rgba(36, 39, 59, 0.3); */
  --scrollbar-bg: rgb(1 2 3 / 40%);
  --theme-bg-color: rgb(255 255 255 / 31%);
    --theme-color: #3c3a3a;
    --inactive-color: #333333;
    --button-inactive: #3c3a3a;
    --search-bg: rgb(255 255 255 / 31%);
    --dropdown-bg: #f7f7f7;
    --overlay-bg: rgb(255 255 255 / 30%);
    --dropdown-hover: rgb(236 236 236);
    --border-color: rgb(255 255 255 / 35%);
    --popup-bg: rgb(255 255 255);
    --hover-menu-bg: rgba(255 255 255 / 35%);
    --scrollbar-bg: rgb(255 253 253 / 57%);
    --content-title-color: --theme-color;
}

.light-mode {
  --theme-bg-color: rgb(255 255 255 / 31%);
  --theme-color: #3c3a3a;
  --inactive-color: #333333;
  --button-inactive: #3c3a3a;
  --search-bg: rgb(255 255 255 / 31%);
  --dropdown-bg: #f7f7f7;
  --overlay-bg: rgb(255 255 255 / 30%);
  --dropdown-hover: rgb(236 236 236);
  --border-color: rgb(255 255 255 / 35%);
  --popup-bg: rgb(255 255 255);
  --hover-menu-bg: rgba(255 255 255 / 35%);
  --scrollbar-bg: rgb(255 253 253 / 57%);
  --content-title-color: --theme-color;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

#homepagebody {
  font-family: var(--body-font);
  /* background-image: url("https://img.freepik.com/premium-vector/project-132_648489-106.jpg"); */
  background-color: rgb(239, 235, 240);
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0.4em;
  width: 100vw;
  height: 100vh;
}

@media screen and (max-width: 480px) {
  #homepagebody {
    padding: 0.4em;
  }
}

.WhiteBlackChecks {
  background-image: url('https://img.freepik.com/premium-vector/black-white-hypnotic-background-vector-illustration-eps-10_118124-9794.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#homepagebody img {
  max-width: 100%;
}

.dark-light {
  position: fixed;
  bottom: 50px;
  right: 30px;
  background-color: var(--dropdown-bg);
  box-shadow: -1px 3px 8px -1px rgba(0, 0, 0, 0.2);
  padding: 8px;
  border-radius: 50%;
  z-index: 3;
  cursor: pointer;
}

.dark-light svg {
  width: 24px;
  flex-shrink: 0;
  fill: #ffce45;
  stroke: #ffce45;
  transition: 0.5s;
}

.light-mode .dark-light svg {
  fill: transparent;
  stroke: var(--theme-color);
}

.light-mode .profile-img {
  border: 2px solid var(--theme-bg-color);
}

.light-mode .content-section ul {
  background-color: var(--theme-bg-color);
}

.light-mode .pop-up__title {
  border-color: var(--theme-color);
}

.light-mode .dropdown.is-active ul {
  background-color: rgba(255, 255, 255, 0.94);
}

#homepagebody.light-mode:before,
#homepagebody.light-mode .video-bg:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.72) 0%, rgba(255, 255, 255, 0.45) 100%);
  -webkit-backdrop-filter: saturate(3);
  backdrop-filter: saturate(3);
}

.app {
  background-color: rgb(239, 235, 240);
  /* max-width: 1250px;
  max-height: 860px; */
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
  border-radius: 14px;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  font-size: 15px;
  font-weight: 500;
}

.header {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  /* height: 58px; */
  width: 100%;
  border-bottom: 1px solid var(--border-color);
  padding: 10px 10px;
  white-space: nowrap;
}

@media screen and (max-width: 480px) {
  .header {
    padding: 10px 10px;
  }
}

.header-menu {
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  background-color: transparent;
  border-radius: 10px;
  width: 90%;
  border: 1px solid rgb(113, 198, 255)
}

/* .header-menu.menu-active {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgb(0, 149, 246);
  border-radius: 10px;
  width: 80%;
} */

.header-menu-add {
  display: flex;
  align-items: center;
}

.header-menu a {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 10px;
  text-decoration: none;
  /* border: 1px solid gray; */
  background-color: transparent;
  color: rgb(0, 149, 246);
  /* border-bottom: 2px solid transparent; */
  transition: 0.3s;
}

.header-menu a.link-active {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 10px;
  text-decoration: none;
  /* border: 1px solid gray; */
  background-color: rgb(0, 149, 246);
  color: white;
  /* border-bottom: 2px solid transparent; */
  transition: 0.3s;
}

.header-menu a:nth-child(1) {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.header-menu a:nth-child(2) {
  border-left: 2px solid rgb(113, 198, 255);
}

.header-menu a:nth-child(3) {
  border-left: 2px solid rgb(113, 198, 255);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.header-menu-results a:nth-child(2) {
  border-left: 2px solid rgb(113, 198, 255);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.header-menu-answers a:nth-child(2) {
  border-left: 2px solid rgb(113, 198, 255);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.header-menu-add a {
  padding: 10px 40px;
  text-decoration: none;
  color: #fff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  /* border-bottom: 2px solid transparent; */
  transition: 0.3s;
}

@media screen and (max-width: 610px) {
  .header-menu a:not(.main-header-link) {
    display: none;
  }
  .header-menu-add a:not(.main-header-link) {
    display: none;
  }
}

/* .header-menu a.is-active,
.header-menu a:hover {
  color: var(--theme-color);
  border-bottom: 2px solid var(--theme-color);
} */

/* .header-menu-add a.is-active,
.header-menu-add a:hover {
  color: var(--theme-color);
  border-bottom: 2px solid var(--theme-color);
} */

.notify {
  position: relative;
}

.notify:before {
  content: "";
  position: absolute;
  background-color: #3a6df0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  right: 20px;
  top: 16px;
}

@media screen and (max-width: 1055px) {
  .notify {
    display: none;
  }
}

.menu-circle {
  width: 15px;
  height: 15px;
  background-color: #f96057;
  border-radius: 50%;
  box-shadow: 24px 0 0 0 #f8ce52, 48px 0 0 0 #5fcf65;
  margin-right: 195px;
  flex-shrink: 0;
}

@media screen and (max-width: 945px) {
  .menu-circle {
    display: none;
  }
}

.search-bar {
  height: 40px;
  display: flex;
  width: 100%;
  /* max-width: 400px; */
  /* padding-left: 16px; */
  border-radius: 10px;
}

.search-bar input {
  width: 100%;
  height: 100%;
  border: none;
  background-color: #fff;
  border-radius: 10px;
  font-family: var(--body-font);
  font-size: 15px;
  font-weight: 500;
  padding: 0 20px 0 40px;
  box-shadow: 0 0 0 2px rgba(134, 140, 160, 0.02);
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.966 56.966' fill='%23717790c7'%3e%3cpath d='M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z'/%3e%3c/svg%3e");
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: 16px 48%;
  color: var(--theme-color);
}

.search-bar input::-moz-placeholder {
  font-family: var(--body-font);
  color: var(--inactive-color);
  font-size: 15px;
  font-weight: 500;
}

.search-bar input:-ms-input-placeholder {
  font-family: var(--body-font);
  color: var(--inactive-color);
  font-size: 15px;
  font-weight: 500;
}

.search-bar input::placeholder {
  font-family: var(--body-font);
  color: var(--inactive-color);
  font-size: 15px;
  font-weight: 500;
}

.header-profile {
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;
  margin-left: auto;
  flex-shrink: 0;
}

.header-profile svg {
  width: 22px;
  color: #f9fafb;
  flex-shrink: 0;
}

.notification {
  position: relative;
}

.notification-number {
  position: absolute;
  background-color: #3a6df0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  right: -6px;
  top: -6px;
}

.notification+svg {
  margin-left: 22px;
}

@media screen and (max-width: 945px) {
  .notification+svg {
    display: none;
  }
}

.profile-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  border: 2px solid var(--theme-color);
  margin-left: 22px;
}

.wide .header-menu,
.wide .header-menu-add,
.wide .header-profile {
  display: none;
}

.wide .search-bar {
  max-width: 600px;
  margin: auto;
  transition: 0.4s;
  box-shadow: 0 0 0 1px var(--border-color);
  padding-left: 0;
}

.wide .menu-circle {
  margin-right: 0;
}

.wrapper {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.left-side {
  flex-basis: 240px;
  border-right: 1px solid var(--border-color);
  padding: 26px;
  overflow: auto;
  flex-shrink: 0;
}

@media screen and (max-width: 945px) {
  .left-side {
    display: none;
  }
}

.side-wrapper+.side-wrapper {
  margin-top: 20px;
}

.side-title {
  color: var(--inactive-color);
  margin-bottom: 14px;
}

.side-menu {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}

.side-menu a {
  text-decoration: none;
  color: var(--theme-color);
  display: flex;
  align-items: center;
  font-weight: 400;
  padding: 10px;
  font-size: 14px;
  border-radius: 6px;
  transition: 0.3s;
}

.side-menu a:hover {
  background-color: var(--hover-menu-bg);
}

.side-menu svg {
  width: 16px;
  margin-right: 8px;
}

.updates {
  position: relative;
  top: 0;
  right: 0;
  margin-left: auto;
  width: 18px;
  height: 18px;
  font-size: 11px;
}

.main-header {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--border-color);
  height: 58px;
  flex-shrink: 0;
}

/* .main-header .header-menu {
  margin-left: 150px;
} */

@media screen and (max-width: 1055px) {
  .main-header .header-menu {
    margin: auto;
  }
  .main-header .header-menu-add {
    margin: auto;
  }
}

.main-header .header-menu a {
  padding: 10px 10px;
}

/* .main-header .header-menu-add a {
  padding: 20px 10px;
} */

.main-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.menu-link-main {
  text-decoration: none;
  color: var(--theme-color);
  padding: 0 30px;
}

@media screen and (max-width: 1055px) {
  .menu-link-main {
    display: none;
  }
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  color: var(--theme-color);
  padding: 20px 40px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgb(239, 235, 240);
  /* margin-top: 20px; */
}

@media screen and (max-width: 510px) {
  .content-wrapper {
    padding: 5px;
  }
}

.content-wrapper-header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  background-image: url("https://www.transparenttextures.com/patterns/cubes.png"), linear-gradient(to right top, #cf4af3, #e73bd7, #f631bc, #fd31a2, #ff3a8b, #ff4b78, #ff5e68, #ff705c, #ff8c51, #ffaa49, #ffc848, #ffe652);
  border-radius: 14px;
  padding: 20px 40px;
}

@media screen and (max-width: 415px) {
  .content-wrapper-header {
    padding: 20px;
  }
}

.content-wrapper.overlay {
  pointer-events: none;
  transition: 0.3s;
  background-color: var(--overlay-bg);
}

.overlay-app {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: all;
  background-color: rgba(36, 39, 59, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.overlay-app.is-active {
  visibility: visible;
  opacity: 1;
}

.img-content {
  font-weight: 500;
  font-size: 17px;
  display: flex;
  align-items: center;
  margin: 0;
}

.img-content svg {
  width: 28px;
  margin-right: 14px;
}

.content-text {
  font-weight: 400;
  font-size: 14px;
  margin-top: 16px;
  line-height: 1.7em;
  color: #ebecec;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-wrapper-context {
  max-width: 350px;
}

.content-button {
  background-color: #3a6df0;
  border: none;
  padding: 8px 26px;
  color: #fff;
  border-radius: 20px;
  margin-top: 16px;
  cursor: pointer;
  transition: 0.3s;
  white-space: nowrap;
}

.content-wrapper-img {
  width: 186px;
  -o-object-fit: cover;
  object-fit: cover;
  margin-top: -25px;
  -o-object-position: center;
  object-position: center;
}

@media screen and (max-width: 570px) {
  .content-wrapper-img {
    width: 110px;
  }
}

.content-section {
  /* margin-top: 30px; */
  display: flex;
  flex-direction: column;
}

.content-section-details {
  /* margin-top: 30px; */
  display: flex;
  flex-direction: column;
}

.content-section-title {
  color: var(--content-title-color);
  margin-bottom: 14px;
}

.content-section ul {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  background-color: var(--content-bg);
  padding-left: 0;
  margin: 0;
  border-radius: 14px;
  border: 1px solid var(--theme-bg-color);
  cursor: pointer;
}

.content-section ul li {
  list-style: none;
  padding: 10px 18px;
  display: flex;
  align-items: center;
  font-size: 16px;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  transition: 0.3s;
}

.content-section ul li:hover {
  background-color: var(--theme-bg-color);
}

.content-section ul li:hover:first-child {
  border-radius: 13px 13px 0 0;
}

.content-section ul li:hover:last-child {
  border-radius: 0 0 13px 13px;
}

.content-section ul li+li {
  border-top: 1px solid var(--border-color);
}

.content-section ul svg {
  width: 28px;
  border-radius: 6px;
  margin-right: 16px;
  flex-shrink: 0;
}

.products {
  display: flex;
  align-items: center;
  width: 150px;
}

@media screen and (max-width: 480px) {
  .products {
    width: 120px;
  }
}

.status {
  margin-left: auto;
  width: 140px;
  font-size: 15px;
  position: relative;
}

@media screen and (max-width: 700px) {
  .status {
    display: none;
  }
}

.status-circle {
  width: 6px;
  height: 6px;
  background-color: #396df0;
  position: absolute;
  border-radius: 50%;
  top: 4px;
  left: -20px;
}

.status-circle.green {
  background-color: #3bf083;
}

.status-button {
  font-size: 15px;
  margin-top: 0;
  padding: 6px 24px;
}

@media screen and (max-width: 390px) {
  .status-button {
    padding: 6px 14px;
  }
}

.status-button.open {
  background: none;
  color: var(--button-inactive);
  border: 1px solid var(--button-inactive);
}

.status-button:not(.open):hover {
  color: #fff;
  border-color: #fff;
}

.content-button:not(.open):hover {
  background: #1e59f1;
}

.menu .dots {
  width: 5px;
  height: 5px;
  background-color: var(--button-inactive);
  border-radius: 50%;
  /* box-shadow: 7px 0 0 0 var(--button-inactive), 14px 0 0 0 var(--button-inactive); */
  margin: 0 2px;
}

@media screen and (max-width: 415px) {
  .adobe-product .menu {
    display: none;
  }
}

.dropdown {
  position: relative;
  height: 53px;
  width: 40px;
  top: -24px;
  display: flex;
  left: -5px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.dropdown ul {
  position: absolute;
  background: var(--dropdown-bg);
  height: 110px;
  width: 120px;
  right: 0;
  top: 20px;
  pointer-events: none;
  opacity: 0;
  transform: translatey(10px);
  transition: all 0.4s ease;
}

.dropdown ul li a {
  text-decoration: none;
  color: var(--theme-color);
  font-size: 12px;
}

.dropdown.is-active ul {
  opacity: 1;
  pointer-events: all;
  transform: translatey(25px);
}

.dropdown.is-active ul li:hover {
  background-color: var(--dropdown-hover);
}

.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 187px;
  margin-left: auto;
}

@media screen and (max-width: 480px) {
  .button-wrapper {
    width: auto;
  }
}

.pop-up {
  position: absolute;
  padding: 30px 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.4);
  transition: all 0.3s;
  z-index: 10;
  background-color: var(--popup-bg);
  width: 500px;
  visibility: hidden;
  opacity: 0;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  white-space: normal;
}

@media screen and (max-width: 570px) {
  .pop-up {
    width: 100%;
  }
}

.pop-up.visible {
  visibility: visible;
  opacity: 1;
}

.pop-up__title {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pop-up__subtitle {
  white-space: normal;
  margin: 20px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8em;
}

.pop-up__subtitle a {
  color: var(--theme-color);
}

.content-button-wrapper .content-button.status-button.open.close {
  width: auto;
}

.content-section .close {
  margin-right: 0;
  width: 24px;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
}

.checkbox-wrapper+.checkbox-wrapper {
  margin: 20px 0 40px;
}

.checkbox {
  display: none;
}

.checkbox+label {
  display: flex;
  align-items: center;
}

.checkbox+label:before {
  content: "";
  margin-right: 10px;
  width: 15px;
  height: 15px;
  border: 1px solid var(--theme-color);
  border-radius: 4px;
  cursor: pointer;
  flex-shrink: 0;
}

.checkbox:checked+label:before {
  background-color: #3a6df0;
  border-color: #3a6df0;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3e%3cpath d='M20 6L9 17l-5-5'/%3e%3c/svg%3e");
  background-position: 50%;
  background-size: 12px;
  background-repeat: no-repeat;
}

.content-button-wrapper {
  margin-top: auto;
  margin-left: auto;
}

.content-button-wrapper .open {
  margin-right: 8px;
}

.apps-card {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100% + 10px);
}

.apps-card-answers {
  width: 100%;
}

/* .apps-card-rankings {
  width: calc(100% + 20px);
} */

.apps-card-create {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.apps-card-test {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100% - 20px);
  background-color: #fff;
}

.selectedOption {
  background-color: #caffc5;
}

.selectedOption .circle {
  background-color: #64ceff;
}

.apps-card-details {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* height: 100vh; */
  width: 100%;
  /* overflow: visible; */
  background-color: #fff;
}

.app-card {
  display: flex;
  flex-direction: row;
  width: calc(33.3% - 20px);
  font-size: 16px;
  background-color: rgb(255 255 255);
  border-radius: 14px;
  border: 1px solid var(--theme-bg-color);
  padding: 5px;
  margin: 10px 10px;
  cursor: default;
  transition: 0.3s ease;
  gap: 20px;
}

.app-card-rankings {
  display: flex;
  flex-direction: row;
  width: calc(33.3% - 20px);
  font-size: 16px;
  background-color: rgb(255 255 255);
  border-radius: 14px;
  border: 1px solid var(--theme-bg-color);
  padding: 5px;
  margin: 10px;
  cursor: default;
  transition: 0.3s ease;
  gap: 5px;
}

.app-card-details {
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  font-size: 16px;
  background-color: rgb(255 255 255);
  border-radius: 14px;
  border: 1px solid var(--theme-bg-color);
  /* padding: 5px; */
  margin: 10px;
  cursor: default;
  transition: 0.3s ease;
  gap: 5px;
}

.app-card-results {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  font-size: 16px;
  background-color: rgb(255 255 255);
  border-radius: 14px;
  border: 1px solid var(--theme-bg-color);
  /* padding: 5px; */
  margin: 10px;
  cursor: default;
  transition: 0.3s ease;
  /* gap: 5px; */
}

.content-section-details-more {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chat-input-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: var(--box-shadow);
  margin-top: auto;
  border-radius: 50px;
  padding: 10px;
  background-color: var(--chat-input);
}

.input-wrapper {
  /* border: 1px solid var(--border-light); */
  border: none;
  border-radius: 4px;
  font-size: 16px;
  display: flex;
  flex: 1;
  overflow: hidden;
  padding: 0 6px 0 12px;
  justify-content: space-between;
  margin: 0 8px;
  background-color: var(--chat-input);
}

.apps-card-details-more {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* height: 100vh; */
  width: calc(100% - 10px);
  /* overflow: visible; */
  background-color: #fff;
  border-radius: 10px;
}

.app-card-details-more {
  display: flex;
  /* flex-direction: column; */
  /* width: calc(100% - 40px); */
  font-size: 16px;
  background-color: rgb(255 255 255);
  border-radius: 14px;
  border: 1px solid var(--theme-bg-color);
  /* padding: 5px; */
  margin: 10px;
  cursor: default;
  transition: 0.3s ease;
  /* gap: 5px; */
}

.creatorDetails {
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}

.app-card-question {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  font-size: 16px;
  background-color: var(--content-bg);
  border-radius: 14px;
  border: 1px solid var(--theme-bg-color);
  padding: 20px;
  margin: 10px;
  cursor: default;
  transition: 0.3s ease;
}

.app-card-create {
  display: flex;
  flex-direction: column;
  width: calc(50% - 20px);
  font-size: 16px;
  background-color: var(--content-bg);
  border-radius: 14px;
  border: 1px solid var(--theme-bg-color);
  padding: 20px;
  margin: 10px;
  cursor: default;
  transition: 0.3s ease;
}

.app-card-more {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* width: calc(33.3% - 20px); */
  font-size: 16px;
  /* background-color: var(--content-bg); */
  border-radius: 14px;
  /* border: 1px solid var(--theme-bg-color); */
  padding: 20px;
  margin: 10px;
  cursor: default;
  transition: 0.3s ease;
}

.app-card-add {
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
  /* width: calc(33.3% - 20px); */
  font-size: 16px;
  /* background-color: var(--content-bg); */
  border-radius: 14px;
  /* border: 1px solid var(--theme-bg-color); */
  padding: 20px;
  margin: 10px;
  cursor: default;
  transition: 0.3s ease;
}

.app-card__subtext-more {
  background-color: #3a6df0;
  color: #fff;
  text-decoration: none;
  transition: 0.4s;
}

/* .app-card:hover {
  transform: scale(1.02);
  background-color: var(--theme-bg-color);
} */

.app-card svg {
  width: 28px;
  border-radius: 6px;
  margin-right: 12px;
  flex-shrink: 0;
}

/* .app-card+.app-card {
  margin-left: 20px;
} */

.app-card span {
  display: flex;
  align-items: center;
}

.app-card-create span {
  display: flex;
  align-items: center;
}

.app-card__subtext {
  font-size: 11px;
  font-weight: 400;
  line-height: 1.6em;
  padding-right: 10px;
}

.testTitle {
  display: inline-block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.fileName {
  display: inline-block;
  width: 120px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.testDescription {
  display: inline-block;
  width: 100px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.creatorName {
  display: inline-block;
  width: 40px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.creatorName {
  display: inline-block;
  width: 40px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.app-card__subtext-question {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6em;
  margin-top: 20px;
  /* border-bottom: 1px solid var(--border-color); */
  padding-bottom: 20px;
}

.app-card-buttons {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-top: 16px;
}

@media screen and (max-width: 1110px) {
  .app-card {
    width: calc(50% - 20px);
  }
  .app-card-rankings {
    width: calc(50% - 20px);
  }

  /* .app-card:last-child {
    margin-top: 20px;
    margin-left: 0px;
  } */
}

@media screen and (max-width: 565px) {
  .app-card {
    width: 100%;
    /* margin-top: 10px; */
  }
  .app-card-rankings {
    width: calc(100% - 20px);
    margin-top: 20px;
  }
  .app-card-create {
    width: calc(100% - 20px);
    margin-top: 20px;
  }

  .app-card {
    margin-left: 0;
  }

  .app-card-rankings {
    margin-left: 0;
  }

  .app-card-create {
    margin-left: 0;
  }
}

::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-bg);
  border-radius: 10px;
}











@import url("https://fonts.googleapis.com/css?family=Quicksand:400,500,700&subset=latin-ext");

/* html {
  position: relative;
  overflow-x: hidden !important;
}

* {
  box-sizing: border-box;
} */

#profilecardbody {
  /* font-family: "Quicksand", sans-serif; */
  font-family: 'Lato', sans-serif;
  color: #324e63;
}

#profilecardbody a,
#profilecardbody a:hover {
  text-decoration: none;
}

#profilecardbody .icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

#profilecardbody .wrapper {
  width: 100%;
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding: 50px 20px;
  padding-top: 100px;
  display: flex;
  background-image: linear-gradient(-20deg, #ff2846 0%, #6944ff 100%);
  display: flex;
  background-image: linear-gradient(-20deg, #ff2846 0%, #6944ff 100%);
}

@media screen and (max-width: 768px) {
  #profilecardbody .wrapper {
    height: auto;
    min-height: 100vh;
    padding-top: 100px;
  }
}

.profile-card {
  width: 100%;
  min-height: 460px;
  margin: auto;
  box-shadow: 0px 8px 60px -10px rgba(13, 28, 39, 0.6);
  background: #fff;
  backdrop-filter: blur(20px);
  border-radius: 12px;
  max-width: 700px;
  position: relative;
}

.profile-card.active .profile-card__cnt {
  filter: blur(6px);
}

.profile-card.active .profile-card-message,
.profile-card.active .profile-card__overlay {
  opacity: 1;
  pointer-events: auto;
  transition-delay: 0.1s;
}

.profile-card.active .profile-card-form {
  transform: none;
  transition-delay: 0.1s;
}

.profile-card__img {
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(-50%);
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  z-index: 4;
  box-shadow: 0px 5px 50px 0px #6c44fc, 0px 0px 0px 7px rgba(107, 74, 255, 0.5);
}

@media screen and (max-width: 576px) {
  .profile-card__img {
    width: 120px;
    height: 120px;
  }
}

.profile-card__img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.profile-card__cnt {
  margin-top: -35px;
  text-align: center;
  padding: 0 20px;
  padding-bottom: 40px;
  transition: all 0.3s;
}

.profile-card__name {
  font-weight: 700;
  font-size: 24px;
  color: #6944ff;
  margin-bottom: 15px;
}

.profile-card__txt {
  font-size: 18px;
  font-weight: 500;
  color: #324e63;
  margin-bottom: 15px;
}

.profile-card__txt strong {
  font-weight: 700;
}

.profile-card-loc {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}

.profile-card-loc__icon {
  display: inline-flex;
  font-size: 27px;
  margin-right: 10px;
}

.profile-card-inf {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 35px;
}

.profile-card-inf__item {
  padding: 10px 35px;
  min-width: 150px;
}

@media screen and (max-width: 768px) {
  .profile-card-inf__item {
    padding: 10px 20px;
    min-width: 120px;
  }
}

.profile-card-inf__title {
  font-weight: 700;
  font-size: 27px;
  color: #324e63;
}

.profile-card-inf__txt {
  font-weight: 500;
  margin-top: 7px;
}

.profile-card-social {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.profile-card-social__item {
  display: inline-flex;
  width: 55px;
  height: 55px;
  margin: 15px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #405de6;
  box-shadow: 0px 7px 30px rgba(43, 98, 169, 0.5);
  position: relative;
  font-size: 21px;
  flex-shrink: 0;
  transition: all 0.3s;
}

@media screen and (max-width: 768px) {
  .profile-card-social__item {
    width: 50px;
    height: 50px;
    margin: 10px;
  }
}

@media screen and (min-width: 768px) {
  .profile-card-social__item:hover {
    transform: scale(1.2);
  }
}

.profile-card-social__item.facebook {
  background: linear-gradient(45deg, #3b5998, #0078d7);
  box-shadow: 0px 4px 30px rgba(43, 98, 169, 0.5);
}

.profile-card-social__item.twitter {
  background: linear-gradient(45deg, #1da1f2, #0e71c8);
  box-shadow: 0px 4px 30px rgba(19, 127, 212, 0.7);
}

.profile-card-social__item.instagram {
  background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
  box-shadow: 0px 4px 30px rgba(120, 64, 190, 0.6);
}

.profile-card-social__item.behance {
  background: linear-gradient(45deg, #1769ff, #213fca);
  box-shadow: 0px 4px 30px rgba(27, 86, 231, 0.7);
}

.profile-card-social__item.github {
  background: linear-gradient(45deg, #333333, #626b73);
  box-shadow: 0px 4px 30px rgba(63, 65, 67, 0.6);
}

.profile-card-social__item.codepen {
  background: linear-gradient(45deg, #324e63, #414447);
  box-shadow: 0px 4px 30px rgba(55, 75, 90, 0.6);
}

.profile-card-social__item.link {
  background: linear-gradient(45deg, #d5135a, #f05924);
  box-shadow: 0px 4px 30px rgba(223, 45, 70, 0.6);
}

.profile-card-social .icon-font {
  display: inline-flex;
}

.profile-card-ctr {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

@media screen and (max-width: 576px) {
  .profile-card-ctr {
    flex-wrap: wrap;
  }
}

.profile-card__button {
  background: none;
  border: none;
  /* font-family: "Quicksand", sans-serif; */
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 19px;
  margin: 15px 35px;
  padding: 15px 40px;
  min-width: 201px;
  border-radius: 50px;
  min-height: 55px;
  color: #fff;
  cursor: pointer;
  backface-visibility: hidden;
  transition: all 0.3s;
}

@media screen and (max-width: 768px) {
  .profile-card__button {
    min-width: 170px;
    margin: 15px 25px;
  }
}

@media screen and (max-width: 576px) {
  .profile-card__button {
    min-width: inherit;
    margin: 0;
    margin-bottom: 16px;
    width: 100%;
    max-width: 300px;
  }

  .profile-card__button:last-child {
    margin-bottom: 0;
  }
}

.profile-card__button:focus {
  outline: none !important;
}

@media screen and (min-width: 768px) {
  .profile-card__button:hover {
    transform: translateY(-5px);
  }
}

.profile-card__button:first-child {
  margin-left: 0;
}

.profile-card__button:last-child {
  margin-right: 0;
}

.profile-card__button.button--blue {
  background: linear-gradient(45deg, #1da1f2, #0e71c8);
  box-shadow: 0px 4px 30px rgba(19, 127, 212, 0.4);
}

.profile-card__button.button--blue:hover {
  box-shadow: 0px 7px 30px rgba(19, 127, 212, 0.75);
}

.profile-card__button.button--orange {
  background: linear-gradient(45deg, #d5135a, #f05924);
  box-shadow: 0px 4px 30px rgba(223, 45, 70, 0.35);
}

.profile-card__button.button--orange:hover {
  box-shadow: 0px 7px 30px rgba(223, 45, 70, 0.75);
}

.profile-card__button.button--gray {
  box-shadow: none;
  background: #dcdcdc;
  color: #142029;
}

.profile-card-message {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 130px;
  padding-bottom: 100px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}

.profile-card-form {
  box-shadow: 0 4px 30px rgba(15, 22, 56, 0.35);
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  padding: 35px;
  transform: scale(0.8);
  position: relative;
  z-index: 3;
  transition: all 0.3s;
}

@media screen and (max-width: 768px) {
  .profile-card-form {
    max-width: 90%;
    height: auto;
  }
}

@media screen and (max-width: 576px) {
  .profile-card-form {
    padding: 20px;
  }
}

.profile-card-form__bottom {
  justify-content: space-between;
  display: flex;
}

@media screen and (max-width: 576px) {
  .profile-card-form__bottom {
    flex-wrap: wrap;
  }
}

.profile-card textarea {
  width: 100%;
  resize: none;
  height: 210px;
  margin-bottom: 20px;
  border: 2px solid #dcdcdc;
  border-radius: 10px;
  padding: 15px 20px;
  color: #324e63;
  font-weight: 500;
  /* font-family: "Quicksand", sans-serif; */
  font-family: 'Lato', sans-serif;
  outline: none;
  transition: all 0.3s;
}

.profile-card input {
  width: 100%;
  resize: none;
  height: 40px;
  margin-bottom: 20px;
  border: 2px solid #dcdcdc;
  border-radius: 10px;
  padding: 15px 20px;
  color: #324e63;
  font-weight: 500;
  /* font-family: "Quicksand", sans-serif; */
  font-family: 'Lato', sans-serif;
  outline: none;
  transition: all 0.3s;
}

.profile-card textarea:focus {
  outline: none;
  border-color: #8a979e;
}

.profile-card__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  background: rgba(22, 33, 72, 0.35);
  border-radius: 12px;
  transition: all 0.3s;
}























.quiz__heading {
  display: none;
}

.result {
  font-size: 2rem;
  color: #117eeb;
}

.heading__text {
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 3rem;
  font-weight: 300;
}

.quiz__heading-text {
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 300;
}

.quiz-form__question {
  margin-bottom: 0.8rem;
  font-size: 1.2rem;
}

.quiz-form__quiz:not(:last-child) {
  margin-bottom: 1.5rem;
}

.quiz-form__ans {
  border-radius: 0.8rem;
  border: 2px solid #264868;
  padding: 0.8rem;
  color: #fff;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  cursor: pointer;
}

.quiz-form__ans:not(:last-child) {
  margin-bottom: 0.5rem;
}

/* .quiz-form input[type="radio"] {
  opacity: 0;
  position: absolute;
  left: 15px;
  z-index: -1;
} */

.design {
  width: 1rem;
  height: 1rem;
  border: 1px solid #a1a9bd;
  border-radius: 100%;
  margin-right: 1rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.design::before,
.design::after {
  content: "";
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  transform: scale(0);
  transform-origin: center center;
}

.design:before {
  background: #a1a9bd;
  opacity: 0;
  transition: 0.3s;
}

.design::after {
  background: #117eeb;
  opacity: 0.4;
  transition: 0.6s;
}

.text {
  backface-visibility: hidden;
  transition: transform 200ms ease-in;
}

/* .quiz-form input[type="radio"]:hover~.text {
  transform: translateX(0.4rem);
}

.quiz-form input[type="radio"]:hover .quiz-form__ans {
  color: #117eeb;
}

.quiz-form input[type="radio"]:checked+.design::before {
  opacity: 1;
  transform: scale(0.6);
}

.quiz-form input[type="radio"]:hover+.design,
.quiz-form input[type="radio"]:focus+.design {
  border: 1px solid #117eeb;
}

.quiz-form input[type="radio"]:hover+.design:before,
.quiz-form input[type="radio"]:focus+.design:before {
  background: #117eeb;
}

.quiz-form input[type="radio"]:focus+.design::after,
.quiz-form input[type="radio"]:active+.design::after {
  opacity: 0.1;
  transform: scale(2);
} */

.submit {
  border: none;
  border-radius: 100vh;
  padding: 0.8rem 1.5rem;
  background: #117eeb;
  color: #fff;
  /* font-family: inherit; */
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 300;
  display: block;
  margin: 1rem auto 4rem auto;
  cursor: pointer;
  transition: transform 200ms ease, box-shadow 200ms ease;
}

.submit:focus {
  outline: none;
}

.submit:hover {
  transform: translateY(-2px) scale(1.015);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.35);
}

.submit:active {
  transform: translateY(0) scale(1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.correct {
  color: #117eeb;
}

.wrong {
  color: crimson;
}